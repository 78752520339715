
.big-image-link {
  position: relative;
  background: $purple;
  color: white;
}

.big-image-link a {
  text-decoration: none;
}

.big-image-link a > * {
  position: relative;
}

.big-image-link h3 {
  margin: 2vw 0;
  @include mq(small-smartphone) {
    max-width: none;
  }
}

.big-image-link img {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.5;
}

.big-image-link p {
  width: 70%;
  @include mq(small-smartphone) {
    width: auto;
  }
}

.big-image-link button {
  margin-top: 3vw;
}