
.partners-list {
  background: $gray;
}

.partners-list h3 {
  @include font-size-medium;
  max-width: none;
  margin-top: 5vw;
  margin-bottom: 3vw;
}

.partners-list ul {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  list-style: none;
  padding: 0;
  margin-left: -1px;
  margin-right: -1px;
}

.partners-list ul li {
  flex: 0 0 calc(33.333333%);
  padding: 5vw;
  display: flex;
  align-items: center;
  background: white;
  border: 1px solid $gray;
  aspect-ratio: 1/1;
  @include mq(smartphone) {
    flex: 0 0 50%;
  }
}

.partners-list ul li a {
  display: block;
  width: 100%;
}

.partners-list ul li img {
  width: 100%;
  mix-blend-mode: multiply;
}

.partners-list figure {
  margin-top: 10vw;
}