
@font-face {
  font-family: 'Armin Soft';
  font-weight: 400;
  src: url('/assets/fonts/ArminSoft-Regular.woff2') format('woff2'),
  url('/assets/fonts/ArminSoft-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Armin Soft';
  font-weight: 300;
  src: url('/assets/fonts/ArminSoft-Normal.woff2') format('woff2'),
  url('/assets/fonts/ArminSoft-Normal.woff') format('woff');
}


@font-face {
  font-family: 'Armin Soft';
  font-weight: 500;
  src: url('/assets/fonts/ArminSoft-SemiBold.woff2') format('woff2'),
  url('/assets/fonts/ArminSoft-SemiBold.woff') format('woff');
}
