/* Nested list */

.nested-list ul {
  list-style: none;
  padding: 0;
}

.nested-list > ul > li h3 {
  margin-top: 0;
  margin-bottom: 2vw;
  @include mq(tablet) {
    margin-top: 0.4em;
  }
  @include mq(small-smartphone) {
    margin-bottom: 0.4em;
    margin-top: 0.7em;
  }
}

.nested-list > ul > li {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding-top: 3vw;
  @include mq(smartphone) {
    padding-top: 8vw;
    flex-direction: column;
  }
}

.nested-list.purple > ul > li {
  border-top: 1px solid transparentize(white, 0.8);
}

.nested-list > ul > li:before {
  content: none;
}

.nested-list > ul > li + li {
  margin-top: 5vw;
}

.nested-list > ul > li > * {
  flex: 0 0 calc(50% - (#{$column-gap} / 2);
  @include mq(tablet) {
    flex: 0 0 calc(50% - (#{$column-gap-tablet} / 2);
  }
  @include mq(smartphone) {
    flex: 0 0 100%;
  }
}

.nested-list > ul ul {
  margin-top: 0.8rem;
}

.nested-list > ul > li ul li + li {
  margin-top: 2.4vw;
  @include mq(small-smartphone) {
    margin-top: 1.5rem;
  }
}

.nested-list > ul > li ul li:before {
  content: "";
  width: 1.1em;
  float: left;
  margin-left: -2.2em;
  margin-right: 0.5rem;
  margin-top: 0.7em;
  border-top: 0.15em solid;
  transform: rotate(0);
}

.nested-list article {
  margin-top: 0;
  column-count: auto;
  column-width: auto;
}

.nested-list .image {
  display: flex;
  @include mq(smartphone) {
    max-height: 34vw;
  }
}

.nested-list .image-icon img {
  padding: 2vw 10vw;
  opacity: 0.4;
}