
.partners-footer {
  padding-bottom: 1px;
  padding-top: 1px;
}

.partners-footer ul {
  display: flex;
  list-style: none;
  align-items: center;
  padding: 0 5vw;
  margin: 3vw 0 2vw;
  position: relative;

  @include mq(tablet) {
    margin: 5vw 0 3vw;
    flex-wrap: wrap;
  }
}

.header + .partners-footer {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.partners-footer li {
  padding: 2vw 1.5vw;
  flex: 1 1 auto;
  text-align: center;
  align-self: stretch;
  display: flex;
  align-items: center;
}

$baseWidth: 9vw;
$baseWidthTablet: 16vw;

.partners-footer li img {
  width: $baseWidth;
  @include mq(tablet) {
    width: $baseWidthTablet;
  }
}

.partners-footer li.medium img {
  width: $baseWidth;
  @include mq(tablet) {
    width: $baseWidthTablet;
  }
}

.partners-footer li.small img {
  width: $baseWidth * 0.8;
  @include mq(tablet) {
    width: $baseWidthTablet * 0.8;
  }
}

.partners-footer li.xsmall img {
  width: $baseWidth * 0.6;
  @include mq(tablet) {
    width: $baseWidthTablet * 0.6;
  }
}

.partners-footer li.xxsmall img {
  width: $baseWidth * 0.4;
  @include mq(tablet) {
    width: $baseWidthTablet * 0.4;
  }
}

.partners-footer li.xxxsmall img {
  width: $baseWidth * 0.2;
  @include mq(tablet) {
    width: $baseWidthTablet * 0.2;
  }
}

.partners-footer li.large img {
  width: $baseWidth * 1.2;
  @include mq(tablet) {
    width: $baseWidthTablet * 1.2;
  }
}

.partners-footer li.xlarge img {
  width: $baseWidth * 1.4;
  @include mq(tablet) {
    width: $baseWidthTablet * 1.4;
  }
}

.partners-footer li.xxlarge img {
  width: $baseWidth * 1.6;
  @include mq(tablet) {
    width: $baseWidthTablet * 1.6;
  }
}

.partners-footer li.xxxlarge img {
  width: $baseWidth * 1.8;
  @include mq(tablet) {
    width: $baseWidthTablet * 1.8;
  }
}

.partners-footer ul:before,
.partners-footer ul li:last-child:before {
  content: 'Founding partners';
  position: absolute;
  text-transform: uppercase;
  top: -10px;
  left: 6.5vw;
  color: #ccc;
  white-space: nowrap;
  @include font-size-small-caps;
  font-size: calc(0.4em + 0.4vw);
  @include mq(tablet) {
    font-size: calc(0.5em + 0.4vw);
  }
  @include mq(smartphone) {
    display: none;
  }
}

.partners-footer ul li:last-child {
  position: relative;
}

.partners-footer ul li:last-child:before {
  content: 'Founding patron';
  left: 1.5vw;
}