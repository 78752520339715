/* Site footer */

.m-site-wrapper > footer {

  padding: $side-padding-wide;
  color: white;
  background: #1a011f;
  display: flex;
  flex-wrap: wrap;
  
  @include mq(tablet) {
    padding: $side-padding-wide-tablet;
  }
  @include mq(smartphone) {
    padding: 12vw $side-padding-wide-tablet;
  }

  .logo img {
    width: 14vw;
    @include mq(smartphone) {
      width: 31vw;
    }
  }

  .outro-text {
    flex: 0 0 100%;
  }

  .outro-text h3 {
    @include font-size-medium;
  }

  .email-signup,
  .contact-us {
    padding: 7vw 0 0;
    flex: 0 0 50%;
    @include mq(tablet) {
      padding: 5vw 0 0;
      flex: 0 0 100%;
    }
  }

  .email-signup p {
    @include font-size-tiny;
  }

  .email-signup h4,
  .contact-us h4 {
    @include font-size-small-caps;
    opacity: 0.5;
  }

  .email-signup h4 {
    margin-bottom: 2rem;
    @include mq(tablet) {
      margin-bottom: 1.6rem;
    }
    @include mq(smartphone) {
      margin-bottom: 1.2rem;
    }
  }

  .email-signup form {
    display: flex;
  }

  .email-signup input[type='submit'] {
    margin-left:0;
  }

  .contact-us a {
    color: $yellow;
    @include font-size-slightly-bigger;
    text-decoration: none;
    display: block;
    margin-bottom: 2.1rem;
  }

  .contact-us ul {
    list-style: none;
    padding: 0;
    display: flex;
    margin-bottom: 0;
  }

  .contact-us ul a {
    background: $purple;
    border-radius: 100px;
    width: calc(2rem + 2vw);
    height: calc(2rem + 2vw);
    margin-right: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all .2s;
  }

  .contact-us ul a:hover {
    background: lighten($purple, 5%);
  }

  .contact-us img {
    width: calc(1rem + 0.9vw);
    height: calc(1rem + 0.9vw);
    max-height: 100%;
  }

  .contact-us span {
    display: none;
  }


}
