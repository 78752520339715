
.m-site-wrapper > header .mobile-logo {
  display: none;
  position: absolute;
  top: 5vw;
  left: 5vw;
  z-index: 1;
  @include mq(smartphone) {
    display: block;
  }
}

.m-site-wrapper > header .mobile-logo img {
  width: 31vw;
  opacity: 1;
  position: static;
}
