// Navbar

nav {
  position: absolute;
  //background: $purple;
  color: white;
  padding: 30px $side-padding-narrow;
  text-align: right;
  width: 100%;
  z-index: 1;
  display: flex;
  align-items: center;
  @include mq(smartphone) {
    flex-wrap: wrap;
    display: none;
  }
}

nav .logo {
  margin-right: auto;
  flex: 0 0 auto;
}

nav .logo img {
  width: 12vw;
  @include mq(tablet) {
    width: 16vw;
  }
}

nav .logo a:hover:after {
  content: none;
}

nav .logo a {
  display: block;
  padding: 0;
  margin: 0;
}

nav .logo a img {
  transform: translate3d(0, 0, 0);
  transition: all .2s;
}

nav .logo a:hover img {
  transform: translate3d(0.3em, 0, 0);
}

nav .menu {
  line-height: 2.2;
  @include mq(smartphone) {
    max-width: none;
    margin-top: 5vw;
  }
}

nav a {
  color: white;
  text-decoration: none;
  padding: 0.6em;
  margin-left: 0.2rem;
  font-weight: 400;
  position: relative;
  transition: all .2s;
  white-space: nowrap;
  display: inline-block;
  line-height: 1;
  font-size: calc(1rem + 0.5vw);
  @include mq(tablet) {
    font-size: calc(0.7rem + 0.7vw);
  }
  @include mq(smartphone) {
    font-size: calc(1rem + 1vw);
    padding-left: 5vw;
    padding-right: 0.4rem;
    margin-left: -5vw;
  }
}

nav a:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  transform-origin: top left;
  transform: scaleX(0);
  transition: all .4s;
  opacity: 0;
  will-change: transform;
}

nav a:hover:after {
  transform: scaleX(1);
  opacity: 1;
}

nav a.active {
  background: white;
  color: $purple;
}

nav a:nth-of-type(5n+1) {
  &:after {
    background: $red;
  }
}

nav a:nth-of-type(5n+2) {
  &:hover {
    color: $purple;
  }

  &:after {
    background: $yellow;
  }
}

nav a:nth-of-type(5n+3) {
  &:after {
    background: $pink;
  }
}

nav a:nth-of-type(5n+4) {
  &:hover {
    color: $purple;
  }

  &:after {
    background: $green;
  }
}

nav a:nth-of-type(5n+5) {
  &:after {
    background: $blue;
  }
}

.fixed-header {
  width: 100%;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  background: $purple;
  transition: all .2s;
  transform: translate(0, -100%);
  box-shadow: 0 1.7px 0 rgba(255, 255, 255, 0.1);
  @include mq(smartphone) {
    display: flex;
    position: fixed;
    height: 100%;
    background: transparentize($purple, 0.1);
    font-size: 6vw;
    top: 0;
    transform: translate(-100%, 0);
  }
}

.fixed-header.is-showing {
  transform: translate(0, 0);
}

.fixed-header.is-tucked {
  transform: translate(0, -100%);
}

.fixed-header nav {
  position: relative;
  padding: 1.5vw 5vw;
  @include mq(smartphone) {
    display: flex;
    flex-direction: column;
    text-align: left;
    align-items: flex-start;
    padding: 5.5vw 5vw;
  }
}

.fixed-header nav a {
  @include mq(smartphone) {
    display: block;
  }
}

.fixed-header nav .logo a img {
  width: 8vw;
  @include mq(tablet) {
    width: 12vw;
  }
  @include mq(smartphone) {
    width: 31vw;
  }
}