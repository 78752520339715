.hamburger-wrap {
  z-index: 500;
  position: fixed;
  display: none;
  z-index: 1000;
  @include mq(smartphone) {
    display: block !important;
    top: 0;
    right: 0;
    transform: scale(0.7);
    transform-origin: top right;
  }
}

.hamburger-wrap .hamburger {
  @include hamburger--squeeze;
}

.hamburger-wrap .hamburger:after {
  content: none;
}

.hamburger-wrap:after {
  content: "";
  width: 100%;
  height: 100%;
  background: transparentize($purple, 0.8);
  pointer-events: none;
  filter: blur(35px);
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}