.plan-graphic .steps,
.plan-graphic .pillars ul {
  display: flex;
  list-style: none;
  padding: 0;
  margin-right: -5.4vw; // Purely to align visually better with bracket
  justify-content: space-between;
  margin: 0 -7vw;
  @include mq(tablet) {
    margin: 0 -5vw;
  }
  @include mq(smartphone) {
    flex-direction: column;
    margin: 0;
  }
}

.plan-graphic .steps li,
.plan-graphic .pillars ul li {
  flex: 1 1 0px;
  position: relative;
  color: white;
  border-radius: 1000px;
  padding: 1.6vw;
  margin: 1.5vw;
  text-align: center;
  @include mq(tablet) {
    border-radius: 20px;
    margin: 0 2vw 2vw 2vw;
    text-align: left;
  }
  @include mq(smartphone) {
    text-align: center;
    padding: 3.2vw;
    margin: 0 0 8vw 0;
    border-radius: 100px;
  }
}

.plan-graphic .steps li:nth-child(1) {
  background: $blue;
}

.plan-graphic .steps li:nth-child(2) {
  background: $yellow;
  color: $purple;
}

.plan-graphic .steps li:nth-child(3) {
  background: $pink;
}

.plan-graphic .steps li:nth-child(4) {
  background: $red;
}

.plan-graphic .steps li:nth-child(5) {
  background: $green;
  color: $purple;
}

.plan-graphic .steps li:after {
  content: "";
  width: 1.2em;
  height: 0.8em;
  margin-left: 0.5em;
  display: inline-block;
  background: url(/assets/images/arrow-white.svg) center/contain no-repeat;
  position: absolute;
  top: 50%;
  right: -2.1vw;
  transform: translate(0, -50%);
  @include mq(tablet) {
    right: -3.1vw;
  }
  @include mq(tablet) {
    content: none;
  }
}

.plan-graphic .steps li:last-child:after {
  content: none;
}

.plan-graphic .steps li:before,
.plan-graphic .pillars ul li:before {
  content: "";
  width: 0.8em;
  height: 1.6em;
  display: inline-block;
  background: url(/assets/images/arrow-white-up-down.svg) center/contain no-repeat;
  position: absolute;
  bottom: -4.1vw;
  left: 50%;
  transform: translate(-50%, 0);
  @include mq(tablet) {
    bottom: -4.9vw;
  }
  @include mq(smartphone) {
    background: url(/assets/images/arrow-white-down.svg) center/contain no-repeat;
    height: 4vw;
    bottom: -6.2vw;
  }
}

.plan-graphic .pillars ul li:before {
  bottom: auto;
  background: url(/assets/images/arrow-white-down.svg) center/contain no-repeat;
  top: -3.8vw;
  @include mq(tablet) {
    top: -4vw;
  }
  @include mq(smartphone) {
    top: -6.1vw;
  }
}

.plan-graphic .pillars ul li:nth-child(n+2):before {
  @include mq(smartphone) {
    display: none;
  }
}

.plan-graphic .steps li > div,
.plan-graphic .pillars ul li > div {
  width: 100%;
  padding-bottom: 100%;
  display: block;
  @include mq(tablet) {
    padding-bottom: 0;
  }
}

.plan-graphic .steps li > div > span,
.plan-graphic .pillars ul li > div > span {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1vw;
  @include mq(tablet) {
    position: static;
    padding: 0;
  }
}

.plan-graphic .steps h4,
.plan-graphic .pillars ul h4 {
  @include font-size-medium;
  font-size: calc(0.7rem + 1.4vw);
  font-weight: 400;
  margin: 0;
  margin-top: -1vw;
  padding-top: 1.1em;
  line-height: 1.2;
  @include mq(tablet) {
    padding-top: 0;
    margin-top: 0.1vw;
  }
}

.plan-graphic .steps p {
  @include font-size-tiny;
  line-height: 1.4;
  @include mq(tablet) {
    margin-bottom: 0;
    margin-top: 0.5em;
  }
}

.plan-graphic .adapt {
  margin: 5vw auto;
  padding: 2vw 2vw;
  position: relative;
  display: block;
  text-align: center;
  border-radius: 19px;
  background: transparentize($yellow, 0.7);
  background: transparentize(white, 0.9);
  color: white;
  @include mq(tablet) {
    border-radius: 20px;
  }
  @include mq(smartphone) {
    margin: 0 0 8vw 0;
  }
}

.plan-graphic .adapt:before {
  border: 3px solid transparentize($yellow, 0.8);
  border-top: 0;
  //content: "";
  display: block;
  height: 1.2vw;
  margin-bottom: 4vw;
}

.plan-graphic .adapt h4 {
  @include font-size-medium;
  font-weight: 400;
  margin: 0;
  margin-left: auto;
  margin-right: auto;
  max-width: 45vw;
  @include mq(tablet) {
    max-width: 50vw;
  }
}

.plan-graphic .adapt p {
  @include font-size-tiny;
  margin-left: auto;
  margin-right: auto;
  max-width: 34vw;
  margin-bottom: 0.3em;
  @include mq(tablet) {
    max-width: 50vw;
    margin-bottom: 0;
  }
}


.plan-graphic .pillars ul {
  width: 70.5%;
  margin: 0 auto;
  @include mq(smartphone) {
    width: auto;
    margin-bottom:8vw;
  }
}

.plan-graphic .pillars ul li {
  background: white;
  color: $purple;
  margin-bottom: 8vw;
  @include mq(smartphone) {
    margin-bottom: 2vw;
  }
}

.plan-graphic .pillars ul li h4 {
  margin-top: 0;
  padding-top: 0;
}

.plan-graphic .pillars article {
  margin-left: auto;
  margin-top: 0;
  margin-bottom: 8vw;
}