$backgroundColor: #ff0000;
$bodyColor: #000000;
$bodyFont: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";


$side-padding-narrow: 5vw;
$side-padding-wide: 10vw;

$side-padding-wide-tablet: 8vw;

$column-gap: 7vw;
$column-gap-tablet: 5vw;


$purple: #2d0037;
$red: #FF456B;
$yellow: #F5CC00;
$pink: #e164c8;
$green: #3cdfb4;
$blue: #3666f2;

$gray: #F4F1F4;

@import 'fonts';
@import 'mixins';

@import 'vendor/hamburgers';

@import 'modules/header';
@import 'modules/footer';
@import 'modules/hamburger';
@import 'modules/navigation';
@import 'modules/plan-graphic';
@import 'modules/nested-list';
@import 'modules/big-image-link';
@import 'modules/partners-list';
@import 'modules/personnel-list';
@import 'modules/vertical-list';
@import 'modules/partners-footer';
@import 'modules/read-next';
@import 'modules/logo-animation';
@import 'modules/news-list';
@import 'modules/highlighted-sponsor';
@import 'modules/mobile-logo';


video {
  width: 100%;
}

a {
  color: inherit;
}

img {
  max-width: 100%;
  height: auto;
}

* {
  box-sizing: border-box;
}

button:hover,
input[type="submit"] {
  cursor: pointer;
}

p {
  max-width: 800px;
}


body {
  font-size: calc(0.7rem + 0.5vw);
  font-family: sans-serif;
  color: #2E0037;
  line-height: 1.6;
  margin: 0;

  font-weight: 300;
}

body, input, button {
  font-family: 'Armin Soft';
}


button {
  font-size: calc(0.7rem + 0.5vw);
  @include font-size-slightly-bigger;
  background: $yellow;
  border: 0;
  text-decoration: none;
  padding: 11px 20px 13px;
  font-weight: 400;

  &:after {
    width: 0.9em;
    height: 0.7em;
    margin-left: 0.5em;
    content: "";
    /* background: #e164c8;*/
    display: inline-block;
    background: url(/assets/images/arrow-purple.svg) center/contain no-repeat;
  }
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 400;
}

h2 {
  @include font-size-small-caps;
}

section > h2 {
  margin-top: 0;
}

section > h2 + h3 {
  margin-top:1em;
}
section > h3 {
  margin-top: 0;
}

h3 {
  @include font-size-big;
  line-height: 1.35;
  max-width: 90%;
}

h4 {
  @include font-size-normal;
  font-weight: 600;
}

section {
  padding: $side-padding-wide;
  position: relative;
  @include mq(tablet) {
    padding: $side-padding-wide-tablet;
  }
  @include mq(smartphone) {
    padding: 12vw $side-padding-wide-tablet;
  }
}


section.red {
  background: #FF456B;
}

section.red figcaption {
  background: #2E0037;
  color: white;
}

section.yellow {
  background: $yellow;
}

section.purple {
  background: #2E0037;
  color: white;
}

section.purple figcaption {
  background: $blue;
  color: white;
}

section.green {
  background: $green;
}

section.pink {
  background: $pink;
}

section.blue {
  background: $blue;
  color: white;
}

section.gray {
  background: $gray;
}

section.gray figcaption {
  background: $yellow;
  color: $purple;
}

.section--image-right {
  display: flex;
  justify-content: space-between;
}

.img--expand {
  margin: 0 -3vw;
  width: calc(100% + 6vw);
  max-width: none;
}

.section--image-right article,
.section--image-right figure {
  flex: 0 0 calc(50% - (#{$column-gap} / 2));
  @include mq(tablet) {
    flex: 0 0 calc(50% - (#{$column-gap-tablet} / 2));
  }
}

article {
  column-count: 2;
  column-width: 280px;
  margin: 8vw 0;
  column-gap: 7vw;
  @include mq(tablet) {
    column-gap: 5vw;
    margin: 8vw 0;
  }
  @include mq(smartphone) {
    margin: 12vw 0;
  }
}

article p {
  break-inside: avoid;
  margin-top:0;
}

article:last-child {
  margin-bottom: 0;
}

article *:first-child {
  margin-top: 0;
}

figure {
  margin: 0;
  display: flex;
  margin-bottom: 100px;
}

figure img {
  align-self: flex-start;
}

figcaption {
  margin-left: calc(-50% + (#{$column-gap} / 2));
  padding: 4vw;
  margin-bottom: -100px;
  align-self: flex-end;
  color: white;
  @include font-size-tiny;
  background: $red;
  @include top-and-tail;
  @include mq(tablet) {
    margin-left: calc(-50% + (#{$column-gap-tablet} / 2));
  }
  @include mq(small-smartphone) {
    margin-left: calc(-66.666666%);
  }
}

figcaption h4 {
  margin-bottom: 1em;
}

form {
  @include form-layout-basics;
  @include form-style-basics;
}

.is-hidden {
  opacity: 0;
  //transform: translate3d(0, 50px, 0);
}

.is-visible {
  opacity: 1;
  //transform: translate3d(0, 0, 0);
}

.is-observed {
  transition: all 0.5s 0.2s ease-out;
}

.map-world {
  margin: -7vw -7vw -7vw -7vw;
}

strong {
  font-weight: 600;
}


// New h3 transitions - just on white basic <sections>, with no class

@keyframes newExposeText {
  0% {
    color: #ded6e0;
    clip-path: polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%);
  }
  40% {
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
    color: #ded6e0;
  }
  100% {
    color: $purple;
  }
}
/*
section:not([class]) h3.is-observed {
  transition: none;
}

section:not([class]) h3.is-hidden {
  transform: none;
  transition: none;
  opacity: 0;
}

section:not([class]) h3.is-visible span {
  animation: newExposeText 1.6s 0s both ease-out;
  opacity: 1;
  transition: none;
}*/

@import 'smooth';

