/* Personnel list */

.personnel-list h3 {
  margin-bottom: 8vw;
}

.personnel-list ul {
  padding: 0;
  list-style: none;
  border-top: 1px solid transparentize($purple, 0.9);
  padding-top: 4vw;
}

.personnel-list ul:last-child {
  margin-bottom: -5vw;
}

.personnel-list li {
  margin-bottom: 8vw;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  @include mq(smartphone) {
    flex: 0 0 100%;
    display: block;
    margin-bottom: 12vw;
  }
}

.personnel-list .image {
  flex: 0 0 calc(50% - #{$column-gap} / 2);
  padding-bottom: calc(50% - 7vw / 2);
  position: relative;
  @include mq(tablet) {
    flex: 0 0 calc(50% - #{$column-gap-tablet} / 2);
  }
  @include mq(smartphone) {
    padding-bottom: 80%;
  }
}

.personnel-list img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  object-fit: cover;
  display: block;
}

.personnel-list img.logo {
  position: absolute;
  right: auto;
  bottom: 0;
  left: 0;
  top: auto;
  background: white;
  width: 13vw;
  height: 13vw;
  padding: 1.1vw;
  object-fit: contain;
  @include mq(smartphone) {
    width: 25vw;
    height: 25vw;
    padding: 3.1vw;
  }
}

.personnel-list article {
  column-width: auto;
  column-count: auto;
  flex: 0 0 calc(50% - #{$column-gap} / 2);
  margin-top: 0;
  @include top-and-tail;
  @include mq(tablet) {
    flex: 0 0 calc(50% - #{$column-gap-tablet} / 2);
  }
  @include mq(smartphone) {
    margin-top: 8vw;
  }
}

.personnel-list h3 {

}

.personnel-list h4 {
  @include font-size-medium;
  font-weight: 400;
  margin-top: 0.5em;
  margin-bottom: 2rem;
  margin-bottom: 0;
  margin-left: -3px;
  @include mq(smartphone) {
    margin-left: -1px;
  }
}

.personnel-list h5 {
  @include font-size-normal;
  font-weight: 500;
}
