.news-list h2 {
  margin-bottom: 5vw;
}

.news-list ul {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.news-list ul li {
  flex: 0 0 calc(50% - (#{$column-gap} / 2));
  @include mq(tablet) {
    flex: 0 0 calc(50% - (#{$column-gap-tablet} / 2));
  }
  @include mq(smartphone) {
    flex: 0 0 100%;
  }
}

.news-list ul li + li {
  @include mq(smartphone) {
    margin-top: 8vw;
  }
}

.news-list h4 {
  @include font-size-slightly-bigger;
  line-height: 1.4;
  margin-bottom: 0.8em;
  margin-top: 1em;
}