
.vertical-list ul {
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0;
  margin-bottom: -5vw;
}

.vertical-list li {
  flex: 0 0 calc(50% - #{$column-gap} / 2);
  margin-bottom: 5vw;
  @include mq(smartphone) {
    flex: 0 0 100%;
  }
}

.vertical-list h3 {
  @include font-size-medium;
  margin-top: 1.6rem;
  @include mq(smartphone) {
    margin-top: 1em;
  }
}

.vertical-list ul {
  counter-reset: my-awesome-counter;
}

.vertical-list li {
  counter-increment: my-awesome-counter;
}

.vertical-list li + li {
  @include mq(smartphone) {
    margin-top: 2em;
  }
}

.vertical-list li::before {
  content: counter(my-awesome-counter);
  width: calc(2rem + 2vw);
  height: calc(2rem + 2vw);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 3px solid #ffffff45;
  border-radius: 100px;
  font-weight: 400;
  @include font-size-medium;
}