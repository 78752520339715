.logo-animation {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 10;
  pointer-events: none;
  background: white;
  padding: 8vw 15vw;
  z-index: 2000;
}

@keyframes swipeAcross {
  0% {
    transform: scaleX(0);
  }
  100% {
    transform: scaleX(1);;
  }
}

.logo-animation:before {
  content: "";
  background: $purple;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  transform-origin: top left;
  transform: scaleX(0);
  animation: swipeAcross 0.6s 0s both ease-out;
}

.logo-animation svg {
  position: relative;
  z-index: 1;
}

.logo-animation #logotype {
  transform: translate3d(-100px, 0, 0);
}

@keyframes bounceIn {
  0% {
    opacity: 0;
    transform: scale(0.95);
    fill: white;
  }
  50% {
    transform: scale(1.05);
    fill: inherit;
  }
  100% {
    transform: scale(1);
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes slideText {
  0% {
    transform: translate3d(-200px, 0, 0);
    opacity: 0;
  }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes glow {
  0% {
    filter: brightness(1);
  }
  50% {
    filter: brightness(1.8);
  }
  100% {
    filter: brightness(1);
  }
}

.logo-animation #blue-pieces,
.logo-animation #yellow-pieces,
.logo-animation #pink-pieces,
.logo-animation #green-pieces,
.logo-animation #red-pieces,
.logo-animation #green-extra-pieces,
.logo-animation #blue-extra-pieces,
.logo-animation #yellow-extra-pieces {
  transform-origin: center;
}

.logo-animation #blue-pieces {
  animation: bounceIn 0.6s 0.7s both ease-out;
  fill: white !important;
}

.logo-animation #yellow-pieces {
  animation: bounceIn 0.6s 0.9s both ease-out;
}

.logo-animation #pink-pieces {
  animation: bounceIn 0.6s 1.1s both ease-out;
}

.logo-animation #green-pieces {
  animation: bounceIn 0.6s 1.3s both ease-out;
}

.logo-animation #red-pieces {
  animation: bounceIn 0.6s 1.5s both ease-out;
}

.logo-animation #green-extra-pieces {
  animation: bounceIn 0.6s 1.7s both ease-out;
}

.logo-animation #blue-extra-pieces {
  animation: bounceIn 0.6s 1.9s both ease-out;
}

.logo-animation #yellow-extra-pieces {
  animation: bounceIn 0.6s 2.1s both ease-out;
}

.logo-animation #extra-pieces {
  animation: fadeOut 0.2s 3.1s both ease-out;
}

.logo-animation #logotype {
  animation: slideText 0.2s 3.3s both ease-out;
}

.logo-animation #whole {
  animation: glow 0.8s 3.5s both ease-in-out;
}

.logo-animation {
  animation: fadeOut 0.2s 5s both ease-out;
}