
@mixin font-size-mega {
  font-size: calc(3rem + 5vw);
  line-height: 1.1;
}

@mixin font-size-big {
  font-size: calc(0.9rem + 3vw);
  line-height: 1.35;
}

@mixin font-size-medium {
  font-size: calc(0.7rem + 2.8vw);
  line-height: 1.35;
}

@mixin font-size-slightly-bigger {
  font-size: calc(0.7rem + 1.4vw);
}

@mixin font-size-normal {
  font-size: calc(0.7rem + 0.5vw);
}



@mixin font-size-tiny {
  font-size: calc(0.6rem + 0.5vw);
}

@mixin font-size-small-caps {
  font-size: calc(0.5em + 0.4vw);
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 1px;
}

@mixin top-and-tail {
  & > *:first-child {
    margin-top: 0;
  }
  & > *:last-child {
    margin-bottom: 0;
  }
}

@mixin button {
  background:$yellow;
  -webkit-appearance: none;
  border-radius: 0;
}

@mixin mq($point) {
  @if $point==wide {
    @media (min-width: 1200px) {
      @content;
    }
  }
  @else if $point==tablet {
    @media (max-width: 1150px) {
      @content;
    }
  }
  @else if $point==small-tablet {
    @media (max-width: 950px) {
      @content;
    }
  }
  @else if $point==smartphone {
    @media (max-width: 767px) {
      @content;
    }
  }
  @else if $point==small-smartphone {
    @media (max-width: 480px) {
      @content;
    }
  }
}

// Form basics

@mixin form-style-basics {

  $form-input-padding: 14px;
  $form-input-padding--smartphone: 10px;

  form {
    line-height: 1.8;
  }

  label,
  .label-substitute {
    font-weight: bold;
    font-size: 18px;
    line-height: 1.4;
    @include mq(smartphone) {
      font-size: 16px;
    }
  }

  input,
  textarea,
  select {
    display:block;
    background: #eee;
    border: 0;
    padding: $form-input-padding;
    font-weight: normal;
    font-size: 18px;
    line-height: 1.4;
    border-radius: 0;
    @include mq(smartphone) {
      font-size: 16px;
      padding: $form-input-padding--smartphone;
    }
  }

  select {
    height:$form-input-padding * 3.8;
  }

  .guide-text {
    border: 3px solid #eee;
  }

  .guide-text {
    h1, h2, h3, h4, h5 {
      font-size: 18px;
      text-transform: uppercase;
      margin-bottom: 1em;
    }
  }

  .helper-text {
    font-size: 12px;
    line-height:1.4;
    opacity:0.7;
  }

}

@mixin form-layout-basics {



  $form-input-padding: 14px;
  $form-input-padding--smartphone: 10px;

  $form-column-width: 280px;
  $form-element-spacing: 20px;

  form {
    margin: 0 0 50px 0;
  }


  // Inputs

  input,
  textarea,
  select {
    width: calc(100% - #{$form-column-width});
    margin-top: 0;
    @include mq(tablet) {
      width: calc(100% - #{$form-column-width});
    }
    @include mq(smartphone) {
      width: 100%;
    }
  }

  .section input,
  .section textarea,
  .section select {
    width: calc(100% - #{$form-column-width} + #{$form-element-spacing});
  }

  .form-group--wordy-label > input,
  .form-group--wordy-label > textarea,
  .form-group--wordy-label > select {
    float: none;
    width: 100%;
  }

  textarea {
    min-height: 250px;
  }

  // Labels

  label,
  .label-substitute {
    width: $form-column-width;
    padding-right: $form-element-spacing;
    float: left;
    padding-top: $form-input-padding;
    @include mq(tablet) {
      width: $form-column-width;
    }
    @include mq(smartphone) {
      float: none;
      padding-right: 0;
      width: auto;
      display: block;
      margin-bottom: 10px;
      padding-top: 11px;
    }
  }

  .section label,
  .section .label-substitute {
    width: $form-column-width - $form-element-spacing;
  }

  .form-group--wordy-label > label {
    width: 100%;
    padding-right: 0;
    padding-top:0;
    float: none;
    margin-bottom: $form-element-spacing;
    display: inline-block;
  }

  // Form groups

  .form-group {
    clear: both;

    margin-bottom: $form-element-spacing;
  }


  .form-group--wordy-label {
    margin-bottom: $form-element-spacing * 1.5;
    margin-top: $form-element-spacing * 1.5;
  }

  // Checkboxes & radios

  .form-group .checkboxes,
  .form-group .radio-buttons {
    float: left;
    width: calc(100% - #{$form-column-width});
    margin: 0;
    padding: $form-input-padding 0 $form-input-padding 30px;
    @include mq(smartphone) {
      padding-top: 11px;
      width: 100%;
      float: none;
    }
  }

  .form-group--wordy-label .checkboxes,
  .form-group--wordy-label .radio-buttons {
    float: none;
    width: 100%;
  }

  .form-group--single-checkbox {
    margin-top: 30px;
    //padding: 15px;
    //background: #eee;
    @include mq(smartphone) {
      padding: 11px;
    }
  }

  .form-group--single-checkbox input {
    float: left;
    width: 30px;
    height: auto;
    margin-top: 6px;
    @include mq(smartphone) {
      width: 20px;
    }
  }

  .form-group--single-checkbox label {
    float: right;
    width: calc(100% - 40px);
    padding: 0;
    height: auto;
    font-weight: normal;
    @include mq(smartphone) {
      margin-bottom: 0;
      width: calc(100% - 30px);
    }
  }

  .form-group .checkbox,
  .form-group .radio-button {
    margin: 0;
  }

  .form-group .checkbox label,
  .form-group .radio-button label {
    width: auto;
    float: none;
    padding: 0;
    font-weight: normal;
  }

  .form-group .checkbox input,
  .form-group .radio-button input {
    width: auto;
    float: left;
    height: auto;
    margin-left: -30px;
    margin-top: 8px;
    font-weight: normal;
    @include mq(smartphone) {
      margin-top: 5px;
    }
  }

  .form-group .radio-button input {
    @include mq(smartphone) {
      margin-top: 3px;
    }
  }

  // Actions (container for buttons)

  .form-actions {
    margin-left: $form-column-width;
    margin-top: 50px;
    @include mq(tablet) {
      margin-left: $form-column-width;
    }
    @include mq(smartphone) {
      margin-left: 0;
      float: right;
    }
  }

  // Buttons

  button,
  input[type='submit'] {
    @include button;
    border: 0;
    width: auto;
    height: auto;
  }

  // Guide text

  .guide-text {
    margin: 40px 0;
    @include top-and-tail;
    border: 3px solid #eee;
    padding:$form-element-spacing;
    @include mq(tablet) {
      margin-bottom: 40px;
      font-size: 14px;
      margin-top: 30px;
    }

  }

  // Helper text

  .helper-text {
    margin-left:$form-column-width;
    margin-bottom:$form-element-spacing / 2;
    @include top-and-tail;
  }

  .section .helper-text {
    margin-left:$form-column-width - $form-element-spacing;
  }

  input + .helper-text {
    margin-bottom:0;
    margin-top:$form-element-spacing / 2;
  }

  .required:after {
    content: "*";
  }

  .form-group-errors {
    color: $red;
  }

  .form-group-errors {
    margin-bottom: 2em;
  }

  .form-group-errors li {
    margin-bottom: 1em;
    padding: 15px;
    background: lighten($red, 45%);
    text-align: center;
    @include mq(smartphone) {
      padding: 11px;
    }
  }

  .form-group_with_errors {
    display: inline;
  }

  .form-group_with_errors label {
    color: $red;
  }

  .section {
    background: #e7e7e7;
    padding: $form-element-spacing;
    margin: ($form-element-spacing * 1.5) 0;
    @include top-and-tail;
  }


}

