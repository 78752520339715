
.highlighted-sponsor {
  padding: 0;
  position: relative;
}

.highlighted-sponsor > a {
  padding: 10vw;
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  @include mq(tablet) {
    padding: 8vw;
  }
  @include mq(smartphone) {
    padding: 12vw 8vw;
    flex-direction: column;
  }
}

.highlighted-sponsor article {
  flex: 0 0 calc(50% - (#{$column-gap}));
  margin: 0;
  @include mq(tablet) {
    flex: 0 0 calc(50% - (#{$column-gap-tablet}));
  }
}

.highlighted-sponsor h3 {
  @include font-size-medium;
  position: relative;
  max-width: none;
  padding-right: 1.1em;
}

.highlighted-sponsor h3:after {
  content: "";
  width: 0.8em;
  height: 0.5em;
  margin-left: 0.3em;
  display: inline-block;
  background: url(/assets/images/arrow-purple.svg) center/contain no-repeat;
  opacity: 1;
  margin-right: -1.1em;
}

.highlighted-sponsor figure {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 50%;
  margin: 0;
  background: $purple;
  @include mq(smartphone) {
    position: relative;
    left: auto;
    margin: 12vw -8vw -12vw -8vw;
  }
}

.highlighted-sponsor figure img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.5;
  @include mq(smartphone) {
    position: static;
  }
}

.highlighted-sponsor figure img.logo {
  padding: 10vw;
  opacity: 1;
  object-fit: contain;
  @include mq(smartphone) {
    position: absolute;
  }
}

.highlighted-sponsor article {
  column-count: auto;
  column-width: auto;
  @include top-and-tail;
}