// Hero

.m-site-wrapper > header {
  color: white;
  height: 90vh;
  position: relative;
  padding-top: 1px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: $side-padding-narrow;
  background: $purple;
}

.m-site-wrapper > header h1 {
  position: relative;
  font-weight: 500;
}

.m-site-wrapper > header p {
  position: relative;
  width: 50%;
  max-width: 900px;
  @include mq(small-smartphone) {
    width: 100%;
  }
}

.m-site-wrapper > header h1 {
  @include font-size-mega;
  position: relative;
  margin-bottom: 1vw;
}

.m-site-wrapper > .header--home h1 {
  font-size: calc(1.8rem + 3vw);
  max-width: 80%;
  @include mq(small-smartphone) {
    max-width: none;
  }
}

.m-site-wrapper > header h1 span {
  display: inline-block;
  position: relative;
}

.m-site-wrapper > header h1 span:after {
  content: "";
  background: $yellow;
  position: absolute;
  width: calc(100% + 40px);
  height: calc(100% + 40px);
  margin: -20px;
  display: block;
  top: 0;
  left: 0;
  //transition:all .6s;
  transform-origin: right;
  transform: scaleX(0);
}

@keyframes pinkdraw {
  0% {
    //transform:scaleX(0%) translate(0,0);
    transform: scaleX(0);
    transform-origin: top left;
  }
  50% {
    transform-origin: top left;
    transform: scaleX(1);
  }
  50.001% {
    transform-origin: top right;
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(0);
  }
}

@keyframes pinkdrawText {
  0% {
    color: rgba(255, 255, 255, 0)
  }
  50% {
    color: rgba(255, 255, 255, 0)
  }
  50.001% {
    color: rgba(255, 255, 255, 1)
  }
  100% {
    color: rgba(255, 255, 255, 1)
  }
}

/*
@keyframes exposeHeaderText {
  0% {
    color: rgba(255, 255, 255, 0.2);
    clip-path: polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%);
  }
  75% {
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
  }
  75.001% {
    color: rgba(255, 255, 255, 0.2)
  }
  100% {
    color: rgba(255, 255, 255, 1)
  }
}
*/


@keyframes exposeHeaderText {
  0% {
    opacity: 0.2;
    clip-path: polygon(0% 0%, 0% 0%, 0% 105%, 0% 105%);
  }
  40% {
    clip-path: polygon(0% 0%, 100% 0%, 100% 105%, 0% 105%);
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}


.m-site-wrapper > header h1.is-hidden {
  opacity: 0;
  transform: none;
  transition: none;
}

.m-site-wrapper > header h1.is-visible span {
  animation: exposeHeaderText 1.2s 0.5s both ease-out;
  opacity: 1;
  transition: none;
}

/*.m-site-wrapper > header h1.is-visible span:after {
  animation:pinkdraw 1s forwards;
}*/


.m-site-wrapper > header h1.is-observed {
  transition: all 0.5s 0.2s ease-out;
}


.m-site-wrapper > header img {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.5;
}

.m-site-wrapper > .header--video img {
  display: none;
}

.m-site-wrapper > .header video {
  display: none;
}

.m-site-wrapper > .header--video video {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.5;
  display: block;
}

.m-site-wrapper > header:after {
  border-radius: 100px;
  padding: 1.3vw;
  width: calc(2rem + 2vw);
  height: calc(2rem + 2vw);
  content: "";
  color: white;
  box-sizing: border-box;
  text-align: center;
  position: absolute;
  bottom: 5vw;
  background: $pink url(/assets/images/arrow-white-down.svg) center/calc(0.5rem + 0.5vw) no-repeat;
}